import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Visacheck1 } from '../../../components/images/support/visa/Visacheck1.generated';
import { Visahistory1 } from '../../../components/images/support/visa/Visahistory1.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "reviewing-visa-check-history"
    }}>{`Reviewing Visa Check History`}</h1>
    <p>{`Previous visa checks can be viewed at any time. This page shows how.`}</p>
    <p>{`Lets get started 🚀`}</p>
    <ol>
      <li parentName="ol">{`Navigate to the users menu and click in to the employee we wish to run a visa check on.`}</li>
    </ol>

    <Visacheck1 mdxType="Visacheck1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Run a visa check at any time and review history of past visa checks by clicking the `}<strong parentName="li">{`Visa check`}</strong>{` button`}</li>
    </ol>

    <Visahistory1 mdxType="Visahistory1" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      